import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IConnection, IExtendedConfigureConnectionData } from 'interfaces/IConfigureConnection';
import useModalStatusScroll from 'hooks/useModalStatusScroll';
import { setOAuthConnectorDetails } from 'services/getDynamicQuery';
import { IStore } from 'interfaces/IStore';
import { reorderFields } from 'utils/reorderConnectionFields';
import { setOauthTokenDetails } from '../store/actions/generalActions';

const useExtendedConfigureConnection = ({
    setConnection,
    oauthConnectorDetails,
    modalStatus,
    id,
    makeApiCall,
    environmentAttachments,
    processEnvironmentAttachments,
    process,
    getConnectionData,
}: IExtendedConfigureConnectionData) => {
    function isEmpty(value: any) {
        return value === '' || (typeof value === 'object' && Object.keys(value).length === 0);
    }

    const dispatch = useDispatch();
    const { spaceGuid } = useSelector((state: IStore) => state.login);

    useModalStatusScroll(modalStatus);

    const { oauthTokenGeneratedStatus } = useSelector((state: IStore) => state.general || {});

    const addValuesToConnection = (connections: IConnection[], data: { [key: string]: any }): IConnection[] => {
        connections.forEach((connection) => {
            const matchedArr = data.find((item: any) => item.IPackInstanceOAuthConnectorDetails && item.IPackInstanceOAuthConnectorDetails.connectionId === connection.id);

            if (matchedArr) {
                const oauthDetails = JSON.parse(matchedArr.IPackInstanceOAuthConnectorDetails.oauthConnectorDetails);
                delete oauthDetails['oauthOptions/OAuth2Config/credentials/@clientSecret'];

                connection.field.forEach((field) => {
                    if (field.id === 'oauthOptions/OAuth2Config/credentials/@clientSecret') {
                        delete field.value;
                    } else if (Object.prototype.hasOwnProperty.call(oauthDetails, field.id)) {
                        const oauthValue = oauthDetails[field.id];
                        if (isEmpty(oauthValue) || field.id === 'oauthOptions/OAuth2Config/credentials/@clientSecret') {
                            delete field.value;
                        }
                        if (field.id === 'oauthOptions/OAuth2Config/credentials/@accessToken') {
                            field.value = matchedArr.isTokenGenerated;
                        } else if (field.value) {
                            if (oauthValue !== undefined && oauthValue !== field.value) {
                                field.value = oauthValue;
                            }
                        } else if (oauthValue !== undefined && !isEmpty(oauthValue)) {
                            field.value = oauthValue;
                        }
                    }
                });
            }
        });
        return connections;
    };

    const saveOAuthConnectorDetails = async (data: any) => {
        const catalogsvcPayload = { ...data };
        catalogsvcPayload.oauthConnectorDetails = catalogsvcPayload?.['0'];
        catalogsvcPayload.catalogGuid = spaceGuid;
        catalogsvcPayload.isTokenGenerated = oauthTokenGeneratedStatus ? oauthTokenGeneratedStatus : false;
        const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
        await makeApiCall(oAuthConnectorDetailsMutation, true);
    };

    useEffect(() => {
        environmentAttachments && processEnvironmentAttachments(environmentAttachments);
    }, [environmentAttachments]);

    useEffect(() => {
        if (process.length) {
            const connectionData: IConnection[] = getConnectionData(process);
            connectionData && setConnection(reorderFields([...connectionData]));
        }
    }, [process]);

    useEffect(() => {
        dispatch(
            setOauthTokenDetails({
                connectionId: '',
                envId: '',
                generatedStatus: false,
                processId: '',
            }),
        );
        oauthConnectorDetails && dispatch(setOauthTokenDetails(oauthConnectorDetails));
    }, [oauthConnectorDetails]);

    return { addValuesToConnection, saveOAuthConnectorDetails };
};

export default useExtendedConfigureConnection;
