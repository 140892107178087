export const cleanEmptyKeys = (data: any) => {
    const cleanedData = { ...data };
    for (const key in cleanedData['0']) {
        if (cleanedData['0'][key] === '' || (typeof cleanedData['0'][key] === 'object' && Object.keys(cleanedData['0'][key]).includes('') && cleanedData['0'][key][''] === '')) {
            delete cleanedData['0'][key];
        }
    }
    return cleanedData;
};

export const removeEmptyAuthorizationParams = (data: any): any => {
    for (const key in data) {
        const oauthOptionsAccessToken = data[key]?.['oauthOptions/OAuth2Config/authorizationParameters'];
        if (oauthOptionsAccessToken) {
            for (const paramKey in oauthOptionsAccessToken) {
                if (paramKey === '' && oauthOptionsAccessToken[paramKey] === '') {
                    delete oauthOptionsAccessToken[paramKey];
                }
            }
        }
        const oauthOptionsAuth = data[key]?.['oauthOptions/OAuth2Config/accessTokenParameters'];
        if (oauthOptionsAuth) {
            for (const paramKey in oauthOptionsAuth) {
                if (paramKey === '' && oauthOptionsAuth[paramKey] === '') {
                    delete oauthOptionsAuth[paramKey];
                }
            }
        }
    }
    return data;
};

export const removeEmptyKeys = (catalogsvcPayload: any) => {
    for (const key in catalogsvcPayload) {
        if (key === '') {
            delete catalogsvcPayload[key];
        }
    }
};
