import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import useBasePageData from 'hooks/useBasePageData';
import useDisplayEnvName from 'hooks/useDisplayEnvName';
import useConditionalRoutes from 'hooks/useConditionalRoutes';
import { getDynamicConnectionQuery, getOAuthConnectorDetailsQuery } from 'services/getDynamicQuery';
import { IStore } from 'interfaces/IStore';
import { useQueryParam } from 'hooks/useQueryParam';
import { IConfigureConnection, IConfigureConnectionData, IConnection, IConnectionsArr } from 'interfaces/IConfigureConnection';
import { setDifferentEnvStep, setSeletedExtensionProcess, updateExtensions } from 'store/actions/generalActions';
import useProcessExtensions from './useProcessExtensions';
import useGenericInstall from './useGenericInstall';
import useConnectionForm from './useConnectionForm';
import { isExtensionExists } from 'utils/Extensions';
import useInstallationStep from './useInstallationStep';
import { ExtensionTypes } from 'constants/extensions';
import useProcessSelection from './useProcessSelection';
import useHandleApiCall from './useHandleApiCall';
import useExtendedConfigureConnection from './useExtendedConfigureConnection';
import { cleanEmptyKeys, removeEmptyAuthorizationParams } from 'utils/cleanFormData';

const useConfigureConnection = (): IConfigureConnectionData => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const { getConfigureConnectionPaths } = useConditionalRoutes();
    const { selectedIPack, selectedEnvironments, modalStatus, isEditInstall, extensions, env } = useSelector((state: IStore) => state.general);
    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env) || { sameEnvConfiguration: false, step: 0 };
    const { isProperties, isDataMaps } = useSelector((state: IStore) => state.general.extensionsStatus) || { isProperties: false, isDataMaps: false };
    const { process, setSelectedProcess, getProcessesForExtension, getExtensionsData } = useProcessExtensions();
    const [connection, setConnection] = useState<IConnection[]>([]);
    const { getCancelBtnLabel, handleUninstall, getExtensionPageTitle } = useGenericInstall();
    const [isLoading, setIsLoading] = useState(false);
    const { makeApiCall } = useHandleApiCall();
    const [oauthConnectorDetails, setIPackOAuthConnectorDetails] = useState<any>(null);
    const [openScheduleAccordions, setOpenScheduleAccordions] = useState<number[]>([0]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch,
    } = useForm();
    const { stepChangeWithOneExtension } = useInstallationStep(step, ExtensionTypes.CONNECTION, sameEnvConfiguration);

    const { spaceGuid } = useSelector((state: IStore) => state.login);

    const { envName } = useDisplayEnvName();
    const { id, envIds } = useQueryParam();
    const nextStep = step + 1;
    const envStep = step || 0;

    // Create dynamic query to pass to the BE
    const { query } = getDynamicConnectionQuery(id, envIds?.split(',') || '');
    const { data: pageData } = useBasePageData<IConfigureConnection>(query);

    const environmentExtensions = pageData?.data.integrationPackEnvironmentAttachments[envStep].environmentExtensions;
    const environmentAttachments = pageData?.data.integrationPackEnvironmentAttachments;
    const processes = getProcessesForExtension(environmentAttachments, 'CONNECTION');
    const { setDefaultProcess } = useProcessSelection(processes);
    const envs = envIds.split(',');

    // get conditional route based on extensions data
    const { intermediatePath, propertiesPath } = getConfigureConnectionPaths ? getConfigureConnectionPaths() : { intermediatePath: '/', propertiesPath: '/' };
    const { getConnectionFormData, getConnectionData } = useConnectionForm(sameEnvConfiguration, extensions, envs, step);

    const processEnvironmentAttachments = (attachments: any) => {
        const extensionsStateArr: IConnectionsArr['result'] = getExtensionsData(attachments, 'CONNECTION');
        dispatch(updateExtensions(extensionsStateArr));
        setIsLoading(false);
    };

    const { addValuesToConnection, saveOAuthConnectorDetails } = useExtendedConfigureConnection({
        connection,
        setConnection,
        oauthConnectorDetails,
        modalStatus,
        id,
        makeApiCall,
        environmentAttachments,
        processEnvironmentAttachments,
        process,
        getConnectionData,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        const cleanedDataKey = cleanEmptyKeys(data);
        const cleanedData = removeEmptyAuthorizationParams(cleanedDataKey);

        getConnectionFormData(cleanedData, process);

        if (!sameEnvConfiguration && step !== (selectedEnvironments?.length as number) - 1) {
            !isProperties && !isDataMaps && dispatch(setDifferentEnvStep(nextStep));

            navigate(propertiesPath);
            return;
        }

        navigate(`${pathname}/${intermediatePath}${search}`);
    };

    useEffect(() => {
        dispatch(setSeletedExtensionProcess(''));
        setIsLoading(true);
    }, []);

    const getOAuthConnectorDetails = async (queries: string[]) => {
        const responses: string[] = [];
        for (const query of queries) {
            const response = await makeApiCall(query, true);
            responses.push(response?.data);
        }
        setIPackOAuthConnectorDetails(responses);
    };
    const handleAccordionToggle = (index: number): void => {
        setOpenScheduleAccordions((prev: number[]) => (prev.includes(index) ? [] : [index]));
    };

    useEffect(() => {
        const targetIds: string[] = [];
        connection.forEach((conn) => {
            conn.field.forEach((field) => {
                if (field.id === 'oauthOptions/OAuth2Config/credentials/@clientSecret') {
                    targetIds.push(conn.id);
                }
            });
        });
        const queries = targetIds.map((connectionId) => {
            const inputData = {
                integrationPackInstanceId: id,
                envId: selectedEnvironments?.[env.step]?.id ?? 'envId',
                processId: process,
                connectionId: connectionId,
                catalogGuid: spaceGuid,
            };

            const { oAuthConnectorDetailsQuery } = getOAuthConnectorDetailsQuery(inputData);
            return oAuthConnectorDetailsQuery;
        });
        getOAuthConnectorDetails(queries);
    }, [process, connection]);

    if (isExtensionExists(environmentExtensions, pageData, envStep)) {
        return {
            connection: [],
            selectedIPack,
            onSubmit,
            handleSubmit,
            register,
            errors,
            setValue,
            handleUninstall,
            t,
            setSelectedProcess,
            processes,
            process,
            getValues,
            reset,
            getConnectionFormData,
            isEditInstall,
            getCancelBtnLabel,
            getExtensionPageTitle,
            isLoading,
            modalStatus,
            envName,
            stepChangeWithOneExtension,
            setDefaultProcess,
            watch,
            saveOAuthConnectorDetails,
            addValuesToConnection,
            getOAuthConnectorDetails,
            openScheduleAccordions,
            handleAccordionToggle,
            setOpenScheduleAccordions,
        };
    }

    return {
        connection,
        selectedIPack,
        onSubmit,
        envName,
        modalStatus,
        isLoading,
        register,
        handleSubmit,
        errors,
        setValue,
        handleUninstall,
        t,
        processes,
        setSelectedProcess,
        process,
        getValues,
        reset,
        getConnectionFormData,
        isEditInstall,
        getCancelBtnLabel,
        getExtensionPageTitle,
        stepChangeWithOneExtension,
        setDefaultProcess,
        watch,
        saveOAuthConnectorDetails,
        addValuesToConnection,
        getOAuthConnectorDetails,
        openScheduleAccordions,
        handleAccordionToggle,
        setOpenScheduleAccordions,
    };
};

export default useConfigureConnection;
